import { FC, useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { FlexColumn } from '../../../style/projectComponents'
import { useMutation } from 'react-query'
import {
  IGlobalDashboardItemsRequest,
  IGlobalDashboardItemsResponse,
  IUserDetailsResponse,
} from '../../../service/user-api/models'
import UserApi from '../../../service/user-api/UserApi'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { useTranslation } from 'react-i18next'
import { LineChartComponent } from '../../DashboardComponents/LineChartComponent'

const BalanceChartWrapper = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  justify-content: start;
  background: ${({ theme }) => theme.colors.gray050};
  padding: 20px;
  border-radius: 0.75rem;
  margin-bottom: 20px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

const ChartTitle = styled.p`
  font-size: 24px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  margin-bottom: 36px;
`

type IBalanceUserProps = {
  user: IUserDetailsResponse
}

export const BalanceUserDashboard: FC<IBalanceUserProps> = props => {
  const { user } = props
  const getDate = (date: string) => format(new Date(date), 'dd.MM.yy', { locale: ru })

  const [balanceStats, setBalanceStats] = useState<
    IGlobalDashboardItemsResponse | { items?: { balanceDiff?: number }[] }
  >(null)

  const { t } = useTranslation()

  const getDashboardItemsQuery = useMutation((data: IGlobalDashboardItemsRequest) =>
    UserApi.getGlobalDashboardItems(data)
  )

  useEffect(() => {
    getDashboardItemsQuery.mutate(
      {
        userId: user?.id,
        takeLast: 200,
      },
      {
        onSuccess: data => {
          console.log(data)
          setBalanceStats({
            ...data,
            items: data.items.map((it, idx) => ({
              ...it,
              date: it.date,
              balance: it.balanceInUsd,
              balanceDiff:
                idx > 0
                  ? (it.balanceInUsd - data.items.at(idx - 1).balanceInUsd).toFixed(2)
                  : it.balanceInUsd,
            })),
          })
        },
      }
    )
  }, [user])

  return (
    <BalanceChartWrapper>
      <ChartTitle>{t('adminUserDetail.graphBalance')}</ChartTitle>
      {user?.id && balanceStats?.items?.length > 0 && (
        <LineChartComponent
          titleKey={[t('common.balanceDiff'), 'operationType']}
          valueKey={['balance', 'balanceDiff']}
          xAxisKey={'date'}
          yAxisKey={'balance'}
          data={balanceStats?.items?.map(it => ({
            ...it,
            date: getDate(it.date),
          }))}
        />
      )}
    </BalanceChartWrapper>
  )
}
