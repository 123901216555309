import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import * as DefComponents from '../../../style/projectComponents'
// import * as TimeComponents from '../../../style/projectTimeComponents'
import { observer } from 'mobx-react-lite'
import MenuModal from '../modal/MenuModal'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { useTranslation } from 'react-i18next'
import LanguageModal from '../modal/LanguageModal'
import { LogoutModal } from '../modal/LogoutModal'
import { HeaderImgDef } from './images/HeaderImgDef'
import { PROJECT_NAME } from '../../../config/serverConstants'
import { HeaderImgTime } from './images/HeaderImgTime'
// import * as Time from './styles/HeaderStylesTime'
// import * as Def from './styles/HeaderStylesDef'
import { FlexRow, StyledButton } from '../../../style/projectComponents'
import styled, { useTheme } from 'styled-components/macro'
import { createPortal } from 'react-dom'
import {
  AppMenu,
  AuthImg,
  AuthText,
  BtnWrapper,
  Content,
  Header,
  RightWrapper,
  SettingsButton,
} from './styles/HeaderStylesDef'
import { Logo } from './styles/HeaderStylesDef'

interface HeaderComponentProps {
  toggleTheme: any
  isDarkTheme: boolean
}

// const StyledButton =
//   PROJECT_NAME === 'Def' ? DefComponents.StyledButton : TimeComponents.StyledButton
// const AppMenu = PROJECT_NAME === 'Def' ? Def.AppMenu : Time.AppMenu
// const BtnWrapper = PROJECT_NAME === 'Def' ? Def.BtnWrapper : Time.BtnWrapper
// const Content = PROJECT_NAME === 'Def' ? Def.Content : Time.Content
// const HeaderLink = PROJECT_NAME === 'Def' ? Def.HeaderLink : Time.HeaderLink
// const LinksWrapper = PROJECT_NAME === 'Def' ? Def.LinksWrapper : Time.LinksWrapper
// const Logo = PROJECT_NAME === 'Def' ? Def.Logo : Time.Logo
// const RightWrapper = PROJECT_NAME === 'Def' ? Def.RightWrapper : Time.RightWrapper
// const SettingsButton = PROJECT_NAME === 'Def' ? Def.SettingsButton : Time.SettingsButton

const LogoWrapper = styled(FlexRow)<{ isLanding: boolean }>`
  display: ${({ isLanding }) => (isLanding ? 'flex' : 'none')};
  @media (max-width: 800px) {
    display: flex;
  }
`

const Images = {
  Def: HeaderImgDef,
  Time: HeaderImgTime,
}

const useOnWindowScroll = callback => {
  const listener = React.useRef<null | any>(null)

  React.useEffect(() => {
    if (listener.current) window.removeEventListener('scroll', listener.current)
    listener.current = window.addEventListener('scroll', callback)
    return () => {
      window.removeEventListener('scroll', listener.current)
    }
  }, [callback])
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ toggleTheme, isDarkTheme }) => {
  const UserStore = useContext(UserStorage)
  const [isScrolled, setIsScrolled] = useState(false)

  useOnWindowScroll(() => {
    if (isScrolled && window.scrollY < 100) {
      setIsScrolled(false)
    } else if (!isScrolled && window.scrollY > 100) {
      setIsScrolled(true)
    }
  })

  const [menuOpen, setMenuOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const isAuth = !!UserStore.accessToken
  const isLanding =
    location.pathname === '/' || location.pathname === '/register' || location.pathname === '/login'
  const isTime = PROJECT_NAME === 'Time' && location.pathname === '/'
  const isAuthPaths = location.pathname === '/login' || location.pathname === '/register'
  const isAdminPaths = location.pathname.includes('admin')
  const theme = useTheme()

  const { t, i18n } = useTranslation()
  const [openLangModal, setOpenLangModal] = useState(false)
  const handleLangMenu = () => {
    setOpenLangModal(bool => !bool)
  }

  const [visibleLogout, setVisibleLogout] = useState<boolean>(false)

  return (
    <>
      <Header className={'header'} isAuth={isAuth} isLanding={isTime} isAuthPath={isAuthPaths}>
        <Content isLanding={isLanding || isAuthPaths}>
          <LogoWrapper isLanding={isLanding || isAuthPaths}>
            {isLanding && PROJECT_NAME === 'Def' ? (
              <Link to={'/'} style={{ display: 'flex', cursor: 'pointer' }}>
                <Logo src={Images[PROJECT_NAME].logo} alt="" isLanding={isLanding} />
              </Link>
            ) : (
              <Link
                to={'/'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Logo
                  src={Images[PROJECT_NAME].logo}
                  alt=""
                  style={{ filter: menuOpen && theme.colors.black === '#000' && 'invert(1)' }}
                />
              </Link>
            )}
          </LogoWrapper>

          <RightWrapper>
            {PROJECT_NAME === 'Def' && (
              <SettingsButton onClick={handleLangMenu} isLanding={isLanding}>
                <img
                  src="/images/interface/header/language.svg"
                  alt=""
                  style={{ filter: !menuOpen && 'none' }}
                />
              </SettingsButton>
            )}
            {!isAuth && (
              <BtnWrapper>
                <StyledButton
                  onClick={() => navigate('/login')}
                  variant={'text'}
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: '#fff',
                  }}
                >
                  <AuthImg src="/images/interface/header/login.png" alt="" />
                  <AuthText>{t('auth.login')}</AuthText>
                </StyledButton>
                <StyledButton
                  onClick={() => navigate('/register')}
                  style={
                    PROJECT_NAME === 'Def'
                      ? {
                          background: '#fff',
                          color: '#000',
                          fontSize: 16,
                          fontWeight: 600,
                          padding: PROJECT_NAME === 'Def' ? 8 : '6px 16px',
                          border: 'none',
                          paddingRight: '4px',
                        }
                      : {}
                  }
                >
                  <AuthImg src="/images/interface/header/signup.png" alt="" />
                  <AuthText>{t('auth.reg_title')}</AuthText>
                </StyledButton>
              </BtnWrapper>
            )}
            {isAuth && (
              <AppMenu onClick={() => setMenuOpen(prev => !prev)} menuOpen={menuOpen} isAdminPaths>
                <img
                  width={18}
                  height={18}
                  alt="menu-toggle"
                  src={menuOpen ? '/images/close16.svg' : '/images/interface/header/menu.svg'}
                  style={{ filter: !menuOpen && 'none' }}
                />
              </AppMenu>
            )}
          </RightWrapper>
        </Content>
        <MenuModal
          isScrolled={true}
          isAuth={isAuth}
          open={menuOpen}
          handleMenu={() => setMenuOpen(prev => !prev)}
          toggleTheme={toggleTheme}
          isDarkTheme={isDarkTheme}
        />
        {openLangModal &&
          createPortal(
            <LanguageModal openMenu={openLangModal} handleMenu={handleLangMenu} />,
            document.body
          )}

        {visibleLogout && createPortal(<LogoutModal setIsOpen={setVisibleLogout} />, document.body)}
      </Header>
    </>
  )
}

export default observer(HeaderComponent)
