import styled from 'styled-components/macro'
import { StyledSection } from '../../global/StyledSection'
import {
  AppTextBlack,
  FlexColumn,
  FlexColumnStart,
  FlexRow,
} from '../../../style/projectComponents'
import { MenuColItem } from './MenuColItem'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'
import UserStorage from '../../../storage/UserStorage/UserStorage'
import { socialLinks } from '../../../config/socialLinks'
import { HeaderImgDef } from '../../global/HeaderComponent/images/HeaderImgDef'
import { HeaderImgTime } from '../../global/HeaderComponent/images/HeaderImgTime'
import { PROJECT_NAME } from '../../../config/serverConstants'

const FooterSectionPage = styled(StyledSection)<{ isAuth?: boolean; isLanding?: boolean }>`
  align-items: center;
  min-width: 100vw;
  position: relative;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.gray050};
  padding-left: ${({ isAuth }) => (isAuth ? '200px' : '50px')};
  display: ${({ isLanding }) => (isLanding ? 'none' : 'block')};

  @media (max-width: 800px) {
    padding-left: 0;
  }
`

const BordersContent = styled(FlexColumn)`
  /* max-width: 120rem; */
  /* align-items: center; */
  position: relative;
  border-top-style: none;
  border-bottom-style: none;
  width: 100%;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    gap: 50px;
  }
`

const FooterContent = styled(FlexRow)`
  width: 100%;
  align-items: flex-start;
  column-gap: 3.125rem;
  justify-content: space-between;
  row-gap: 2.125rem;

  @media (max-width: 1350px) {
    flex-direction: column;
    align-items: center;
  }
`

const LogoWrapper = styled(FlexColumnStart)`
  text-align: left;

  p {
    font-size: 0.875rem;
    margin-top: 1rem;
    color: #aab0b8;
  }

  img {
    filter: ${({ theme }) => theme.colors.black === '#000' && 'invert(1)'};
    width: 100px;
  }

  @media (max-width: 768px) {
    text-align: center;
    align-items: center;
  }
`

const MenuWrapper = styled(FlexColumnStart)`
  span {
    font-size: 0.875rem;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.black};
  }

  .column_menu {
    row-gap: 1.5rem;
  }
`

const MenuAndLinks = styled(FlexRow)`
  width: 100%;
  gap: 8rem;
  justify-content: unset;
  align-items: start;

  @media (max-width: 1350px) {
    padding-bottom: 1.75rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }
`

const Inner = styled(FlexColumnStart)`
  gap: 2.125rem;
  /* max-width: 81.25rem; */
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  padding: 0rem 1.25rem;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`

const Menu = styled(FlexRow)`
  @media (max-width: 1350px) {
    gap: 1.5rem;
    justify-content: flex-start;
    align-items: baseline;
  }
`
const SocialLinkImage = styled.img`
  cursor: pointer;
  height: 24px;
  width: 24px;
  padding: 4px;
  margin: 0 10px;
  background: ${({ theme }) => theme.colors.lightGrayBorder};
  border-radius: 100%;
`
const Community = styled(FlexColumnStart)`
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`
const Images = {
  Def: HeaderImgDef,
  Time: HeaderImgTime,
}
export const Footer = () => {
  const location = useLocation()
  const isLandingPage = location.pathname === '/'
  const hideFooter = location.pathname === '/login' || location.pathname === '/register'

  const { t } = useTranslation()

  const headerAuthLinks = [
    {
      link: '/dashboard',
      title: t('menu.dashboard'),
    },
    {
      link: '/assets',
      title: t('menu.deposit'),
    },
    {
      link: '/settings',
      title: t('menu.settings'),
    },
  ]

  const UserStore = useContext(UserStorage)
  const isAuth = !!UserStore.accessToken
  const isLanding = location.pathname === '/' && PROJECT_NAME === 'Time'

  return (
    <FooterSectionPage isAuth={isAuth} isLanding={isLanding}>
      <BordersContent>
        <Inner>
          <FooterContent>
            <MenuAndLinks>
              <LogoWrapper>
                <img src={Images[PROJECT_NAME].logo} alt="logo" />
                {PROJECT_NAME === 'Def' ? (
                  <p>© 2025 Defence Investments</p>
                ) : (
                  <p>© 2025 Time Investments</p>
                )}
              </LogoWrapper>
              {!isLandingPage && isAuth && (
                <MenuWrapper>
                  <Menu style={{ gap: 20 }}>
                    <MenuColItem items={headerAuthLinks} />
                  </Menu>
                </MenuWrapper>
              )}
            </MenuAndLinks>
          </FooterContent>
        </Inner>
        <Inner style={{ marginTop: 20 }}>
          <Community>
            <AppTextBlack style={{ marginBottom: 20 }}>{t('footer.community')}</AppTextBlack>
            <FlexRow>
              {socialLinks.map((linkItem, idx) => (
                <a href={linkItem.link} rel="noreferrer" target="_blank" key={idx}>
                  <SocialLinkImage src={linkItem.image} key={linkItem.link} alt="" />
                </a>
              ))}
            </FlexRow>
          </Community>
        </Inner>
      </BordersContent>
    </FooterSectionPage>
  )
}
