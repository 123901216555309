import styled from 'styled-components/macro'
import { FlexColumn, StyledBg } from '../../style/projectComponents'
import { useEffect } from 'react'
import { InvestMethodsComponent } from '../../components/DashboardComponents/InvestMethodsComponent'
import { useGetApiInvestPoolGetAllInvestPools } from '../../service/api/generated'
import { PageLoader } from '../../components/global/PageLoader'
import PageWrapper from '../../components/global/PageWrapper'

const MarketWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  padding: 26px 16px;
  border-radius: 8px;
  margin-top: 10px;
`
const LoaderWrapper = styled(FlexColumn)`
  margin-top: 50px;
  margin-bottom: 50px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`
export const AdminInvestPools = () => {
  const { data } = useGetApiInvestPoolGetAllInvestPools({ take: 10000 })
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <PageWrapper>
      <StyledBg>
        <MarketWrapper>
          {data?.data?.items ? (
            <InvestMethodsComponent investPools={data?.data?.items} isAdmin />
          ) : (
            <LoaderWrapper>
              <PageLoader />
            </LoaderWrapper>
          )}
        </MarketWrapper>
      </StyledBg>
    </PageWrapper>
  )
}
