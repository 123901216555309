import { useContext } from 'react'
import styled from 'styled-components/macro'
import { AppStyle, FlexColumn } from '../style/projectComponents'
import { MainSection } from '../components/Home/sections/MainSection'
import UserStorage from '../storage/UserStorage/UserStorage'
import { WhyChooseSection } from '../components/Home/sections/WhyChooseSection'
import { ExploreSection } from '../components/Home/sections/ExploreSection'

const StyledBg = styled(FlexColumn)<{ isAuth: boolean }>`
  width: 100vw;
  height: auto;
  min-height: 100%;
  justify-content: center;
  max-width: 120rem;
  background: #000;
  margin-left: ${({ isAuth }) => (isAuth ? '200px' : 0)};
  * {
    z-index: 10;
  }
  @media screen and (max-width: 800px) {
    margin-left: 0;
  }
`

export const HomePage = () => {
  const UserStore = useContext(UserStorage)
  const isAuth = !!UserStore.accessToken

  return (
    <AppStyle isLandingPage={true} isAuth={isAuth} style={{ background: '#000' }}>
      <FlexColumn style={{ width: '100vw' }}>
        <StyledBg isAuth={isAuth}>
          <MainSection />
          <WhyChooseSection />
          <ExploreSection />
        </StyledBg>
      </FlexColumn>
    </AppStyle>
  )
}
